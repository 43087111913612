import { useState, useCallback, useMemo, useEffect, useRef } from "react";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const useFormDownoladCatalog = url => {
  const [lang, setLang] = useState("ru");
  useEffect(() => {
    if (window.location.href.split("-")[2] === "en") {
      setLang("en");
    } else {
      setLang("ru");
    }
  }, []);

  const [isDisable, setDisable] = useState(true);

  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState(false);

  const handleChangeName = useCallback(e => {
    setName(e.target.value);
    // if (!e.target.value) {
    //   setErrorName(true);
    // } else {
    //   setErrorName(false);
    // }
  }, []);

  //phoneNumber
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const handleChangePhoneNumber = useCallback(e => {
    //add plus
    if (e.target.value.substring(0, 1) === "+") {
      setPhoneNumber(e.target.value);
    } else {
      setPhoneNumber("+" + e.target.value);
    }
    //remove plus  if empty field
    if (e.target.value === "+") {
      setPhoneNumber("");
    }

    // if (e.target.value.length < 6) {
    //   setErrorPhoneNumber(true);
    // } else {
    //   setErrorPhoneNumber(false);
    // }
  }, []);

  const handleFocusPhoneNumber = useCallback(e => {
    //add plus
    if (e.target.value.substring(0, 1) === "+") {
      setPhoneNumber(e.target.value);
    } else {
      setPhoneNumber("+" + e.target.value);
    }
    //remove plus  if empty field
    if (e.target.value === "+") {
      setPhoneNumber("");
    }
  }, []);

  const handleBlurPhoneNumber = useCallback(e => {
    if (e.target.value === "+") setPhoneNumber("");
  }, []);

  //email
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const handleChangeEmail = useCallback(e => {
    setEmail(e.target.value);
    const isValid = validateEmail(e.target.value);
    setErrorEmail(false);
    // if (!isValid) {
    //   setErrorEmail(true);
    // } else {
    //   setErrorEmail(false);
    // }
  }, []);

  const downoladLink = useRef(null);

  /* FORM SUBMIT */
  const handleDownloadCatalog = useCallback(
    e => {
      if (!validateEmail(email)) return setErrorEmail(true);
      setErrorEmail(false);

      window.open(url, "_blank");
      const sendOrderToEmail = async e => {
        e.preventDefault();

        const response = await fetch("/api/form", {
          method: "POST",
          body: JSON.stringify({ Форма: "Скачать каталог", Name: name, PhoneNumber: phoneNumber, Email: email }),
          headers: {
            "content-type": "application/json",
          },
        });

        if (!response.ok) {
        } else {
        }
      };

      sendOrderToEmail(e);
    },
    [email, name, phoneNumber, url]
  );

  // useEffect(() => {
  //   if (email === "") {
  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [email, errorEmail, errorName, errorPhoneNumber, isDisable, name, phoneNumber]);

  return useMemo(
    () => ({
      formState: { isDisable, lang },
      formRef: { downoladLink },
      formImput: { name, phoneNumber, email },
      formErrors: { errorName, errorPhoneNumber, errorEmail },
      formHandle: {
        handleFocusPhoneNumber,
        handleBlurPhoneNumber,
        handleChangeName,
        handleChangePhoneNumber,
        handleChangeEmail,
        handleDownloadCatalog,
      },
    }),
    [
      isDisable,
      lang,
      name,
      phoneNumber,
      email,
      errorName,
      errorPhoneNumber,
      errorEmail,
      handleFocusPhoneNumber,
      handleBlurPhoneNumber,
      handleChangeName,
      handleChangePhoneNumber,
      handleChangeEmail,
      handleDownloadCatalog,
    ]
  );
};
