import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import { CashAreaEquipmentHeroContent } from "./CashAreaEquipmentHeroContent";
import "./styles.css";

const CashAreaEquipmentHero = ({ image, alt, title, text, solutions, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className="[ CashAreaEquipmentHero ] [ Content sm:Content_medium ]">
      <CashAreaEquipmentHeroContent
        className="CashAreaEquipmentHero__content"
        title={title}
        text={text}
        solutions={solutions}
        buttonLabel={buttonLabel}
        onOpenContactUsModal={onOpenContactUsModal}
      />

      <Image className="CashAreaEquipmentHero__image CashAreaEquipmentHero__image_desktop" image={image} alt={alt} />
      <Image
        className="CashAreaEquipmentHero__image CashAreaEquipmentHero__image_mobile"
        image={{ childImageSharp: { fluid: { ...image.childImageSharp.fluid, aspectRatio: 1.34 } } }}
        alt={alt}
      />
    </div>
  );
};

CashAreaEquipmentHero.getLivePreviewData = ({ data, getAsset }) => ({
  cashAreaEquipmentHero: {
    ...data.cashAreaEquipmentHero,
  },
});

const cashAreaEquipmentHeroQuery = graphql`
  fragment CashAreaEquipmentHeroFragment on MarkdownRemarkFrontmatter {
    cashAreaEquipmentHero {
      image {
        childImageSharp {
          fluid(maxWidth: 1266) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      solutions
      buttonLabel
    }
  }
`;

export { CashAreaEquipmentHero, cashAreaEquipmentHeroQuery };
