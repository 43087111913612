import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";
import { CashAreaEquipmentDownloadCatalogContent } from "./CashAreaEquipmentDownloadCatalogContent/ShopEquipmentDownloadCatalogContent";

const CashAreaEquipmentDownloadCatalog = ({
  image,
  alt,
  title,
  text,
  categories,
  emailFieldPlaceholder,
  buttonLabel,
  catalog,
}) => {
  return (
    <div className="ShopEquipmentDownloadCatalog" id="download-catalog">
      <div className="[ ShopEquipmentDownloadCatalog__container ] [ Content sm:Content_medium ]">
        <CashAreaEquipmentDownloadCatalogContent
          className="ShopEquipmentDownloadCatalog__content"
          title={title}
          text={text}
          categories={categories}
          emailFieldPlaceholder={emailFieldPlaceholder}
          buttonLabel={buttonLabel}
          catalog={catalog}
        />

        <Image className="ShopEquipmentDownloadCatalog__image" image={image} alt={alt} />
      </div>
    </div>
  );
};

CashAreaEquipmentDownloadCatalog.getLivePreviewData = ({ data, getAsset }) => ({
  cashAreaEquipmentDownloadCatalog: {
    ...data.cashAreaEquipmentDownloadCatalog,
    image: getAsset(data.cashAreaEquipmentDownloadCatalog.image).url,
  },
});

const cashAreaEquipmentDownloadCatalogQuery = graphql`
  fragment CashAreaEquipmentDownloadCatalogFragment on MarkdownRemarkFrontmatter {
    cashAreaEquipmentDownloadCatalog {
      image {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      categories
      emailFieldPlaceholder
      buttonLabel

      catalog {
        publicURL
      }
    }
  }
`;

export { CashAreaEquipmentDownloadCatalog, cashAreaEquipmentDownloadCatalogQuery };
