import { graphql } from "gatsby";
import React from "react";
import backgroundLeft from "./backgroundLeft.svg";
import backgroundRight from "./backgroundRight.svg";
import { CashAreaEquipmentSolution } from "./CashAreaEquipmentSolution";
import "./styles.css";

const CashAreaEquipmentSolutions = ({ title, solutions }) => {
  return (
    <section className="CashAreaEquipmentSolutions">
      <img
        src={backgroundLeft}
        className="CashAreaEquipmentSolutions__background CashAreaEquipmentSolutions__background_left"
        alt=""
      />
      <img
        src={backgroundRight}
        className="CashAreaEquipmentSolutions__background CashAreaEquipmentSolutions__background_right"
        alt=""
      />

      <div className="[ CashAreaEquipmentSolutions__content ] [ Content md:Content_small sm:Content_medium ]">
        <p className="[ CashAreaEquipmentSolutions__title ] [ Font_bigText sm:Font_regularText ]">{title}</p>
        <div className="CashAreaEquipmentSolutions__solutions">
          {solutions.map(solution => (
            <CashAreaEquipmentSolution
              key={solution.title}
              background={solution.background}
              title={solution.title}
              description={solution.description}
              alt={solution.alt}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

CashAreaEquipmentSolutions.getLivePreviewData = ({ data, getAsset }) => ({
  cashAreaEquipmentSolutions: {
    ...data.cashAreaEquipmentSolutions,
    solutions: data.cashAreaEquipmentSolutions.solutions.map(solution => ({
      ...solution,
      background: getAsset(solution.background).url,
    })),
  },
});

const cashAreaEquipmentSolutionsQuery = graphql`
  fragment CashAreaEquipmentSolutionsFragment on MarkdownRemarkFrontmatter {
    cashAreaEquipmentSolutions {
      title
      solutions {
        background {
          childImageSharp {
            fluid(maxWidth: 1088) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { CashAreaEquipmentSolutions, cashAreaEquipmentSolutionsQuery };
