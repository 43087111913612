import { graphql } from "gatsby";
import React from "react";
import { Button } from "../Button";
import { Image } from "../Image";
import "./styles.css";

const CashAreaEquipmentAdvantages = ({ image, subtitle, title, alt, text, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className="[ CashAreaEquipmentAdvantages ] [ Content sm:Content_medium ]">
      <Image className="CashAreaEquipmentAdvantages__image" image={image} alt={alt} />

      <div className="CashAreaEquipmentAdvantages__content">
        <p className="Font_smallHeader sm:Font_mediumTextBold Color_middleGrey">{subtitle}</p>
        <h2 className="[ CashAreaEquipmentAdvantages__title ] [ Font_header sm:Font_smallHeader ]">{title}</h2>
        <p className="[ CashAreaEquipmentAdvantages__text ] [ Font_bigText ]">{text}</p>
        <Button className="CashAreaEquipmentAdvantages__button" onClick={onOpenContactUsModal}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

CashAreaEquipmentAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  cashAreaEquipmentAdvantages: {
    ...data.cashAreaEquipmentAdvantages,
  },
});

const cashAreaEquipmentAdvantagesQuery = graphql`
  fragment CashAreaEquipmentAdvantagesFragment on MarkdownRemarkFrontmatter {
    cashAreaEquipmentAdvantages {
      image {
        childImageSharp {
          fluid(maxWidth: 1248) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      subtitle
      title
      text
      buttonLabel
    }
  }
`;

export { CashAreaEquipmentAdvantages, cashAreaEquipmentAdvantagesQuery };
