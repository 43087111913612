import React from "react";
import { Button } from "../../Button";
import "./styles.css";

const CashAreaEquipmentHeroContent = ({ className, title, text, solutions, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className={className}>
      <h1 className="Font_superHeader sm:Font_smallHeader">{title}</h1>
      <p className="[ CashAreaEquipmentHeroContent__text ] [ Font_bigText sm:Font_regularText Color_darkGrey ]">
        {text}
      </p>
      <ul className="[ CashAreaEquipmentHeroContent__solutions ] [ Font_mediumTextBold sm:Font_smallTextBold ]">
        {solutions.map(solution => (
          <li key={solution}>• {solution}</li>
        ))}
      </ul>
      <Button className="CashAreaEquipmentHeroContent__button" onClick={onOpenContactUsModal}>
        {buttonLabel}
      </Button>
    </div>
  );
};

export { CashAreaEquipmentHeroContent };
