import { graphql } from "gatsby";
import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { CashAreaEquipmentAdvantages } from "../components/CashAreaEquipmentAdvantages/CashAreaEquipmentAdvantages";
import { CashAreaEquipmentCompletedProjects } from "../components/CashAreaEquipmentCompletedProjects";
import { CashAreaEquipmentHero } from "../components/CashAreaEquipmentHero/CashAreaEquipmentHero";
import { CashAreaEquipmentSolutions } from "../components/CashAreaEquipmentSolutions";
import { ContactUsModal } from "../components/ContactUsModal";
import { Layout } from "../components/Layout";
import { CashAreaEquipmentDownloadCatalog } from "../components/CashAreaEquipmentDownloadCatalog";

const CashAreaEquipmentPageTemplate = ({ layout, ...props }) => {
  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState();
  const handleOpenContactUsModal = useCallback(() => setIsOpenContactUsModal(true), []);
  const handleCloseContactUsModal = useCallback(() => setIsOpenContactUsModal(false), []);

  return (
    <Layout {...layout} onOpenContactUsModal={handleOpenContactUsModal} lang={props.layoutId}>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <div style={{ boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.16)", position: "relative", zIndex: 1 }}>
        <CashAreaEquipmentHero {...props.cashAreaEquipmentHero} onOpenContactUsModal={handleOpenContactUsModal} />
      </div>
      <CashAreaEquipmentDownloadCatalog {...props.cashAreaEquipmentDownloadCatalog} />
      <CashAreaEquipmentSolutions {...props.cashAreaEquipmentSolutions} />
      <CashAreaEquipmentCompletedProjects {...props.cashAreaEquipmentCompletedProjects} />
      <CashAreaEquipmentAdvantages
        {...props.cashAreaEquipmentAdvantages}
        onOpenContactUsModal={handleOpenContactUsModal}
      />

      <ContactUsModal isOpen={isOpenContactUsModal} onClose={handleCloseContactUsModal} {...layout.contactUsModal} />
    </Layout>
  );
};

CashAreaEquipmentPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...CashAreaEquipmentHero.getLivePreviewData(props),
  ...CashAreaEquipmentSolutions.getLivePreviewData(props),
  ...CashAreaEquipmentCompletedProjects.getLivePreviewData(props),
  ...CashAreaEquipmentAdvantages.getLivePreviewData(props),
  ...CashAreaEquipmentDownloadCatalog.getLivePreviewData(props),
});

const CashAreaEquipmentPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return <CashAreaEquipmentPageTemplate layout={data.layout.frontmatter} {...frontmatter} />;
};

const pageQuery = graphql`
  query CashAreaEquipmentPageTemplate($id: String!, $layoutId: String!) {
    layout: markdownRemark(frontmatter: { id: { eq: $layoutId } }) {
      frontmatter {
        ...LayoutFragment
        ...ContactUsModalFragment
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        layoutId
        seo {
          title
          description
          keywords
        }

        ...CashAreaEquipmentSolutionsFragment
        ...CashAreaEquipmentHeroFragment
        ...CashAreaEquipmentCompletedProjectsFragment
        ...CashAreaEquipmentAdvantagesFragment
        ...CashAreaEquipmentDownloadCatalogFragment
      }
    }
  }
`;

export default CashAreaEquipmentPage;

export { pageQuery, CashAreaEquipmentPageTemplate };
