import React from "react";
import { Image } from "../../Image";
import "./styles.css";

const CashAreaEquipmentSolution = ({ title, background, alt, description }) => {
  return (
    <div className="CashAreaEquipmentSolution">
      <Image className="CashAreaEquipmentSolution__image" image={background} alt={alt} />
      <div className="CashAreaEquipmentSolution__content">
        <div className="Font_smallHeader sm:Font_mediumTextBold">{title}</div>
        <div className="Font_regularText Color_darkGrey">{description}</div>
      </div>
    </div>
  );
};

export { CashAreaEquipmentSolution };
