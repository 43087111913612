import { graphql } from "gatsby";
import React from "react";
import { CompletedProjects } from "../CompletedProjects/CompletedProjects";
import "./styles.css";

const CashAreaEquipmentCompletedProjects = ({ title, examples }) => {
  return <CompletedProjects title={title} examples={examples} />;
};

CashAreaEquipmentCompletedProjects.getLivePreviewData = ({ data, getAsset }) => ({
  cashAreaEquipmentCompletedProjects: {
    ...data.cashAreaEquipmentCompletedProjects,
    examples: data.cashAreaEquipmentCompletedProjects.examples.map(example => ({
      ...example,
      photo: getAsset(example.photo).url,
    })),
  },
});

const cashAreaEquipmentCompletedProjectsQuery = graphql`
  fragment CashAreaEquipmentCompletedProjectsFragment on MarkdownRemarkFrontmatter {
    cashAreaEquipmentCompletedProjects {
      title
      examples {
        photo {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { CashAreaEquipmentCompletedProjects, cashAreaEquipmentCompletedProjectsQuery };
